




import { UserSessionInvalidDialog } from '@/shared/components';
import { defineComponent } from '@vue/composition-api';
import { userModule } from '../+state';

export default defineComponent({
  components: { UserSessionInvalidDialog },
  setup() {
    return {
      error: userModule.injectStore().error?.message,
    };
  },
});
