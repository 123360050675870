import { createProjector } from '@conversa/sink';
import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
import { LanguagesCapabilities } from '@conversa/bedazzled/src/models/languages-capabilities.interface';

export interface CapabilitiesProjectionsMap {
  'capabilities.loading': boolean;
  'capabilities.features.caregiver': boolean;
  'capabilities.features.feedback': boolean;
  'capabilities.features.patientEdit': boolean;
  'capabilities.demographics': Demographics;
  'capabilities.languages': LanguagesCapabilities;
}

export const capabilitiesProjector = createProjector<
  CapabilitiesProjectionsMap,
  CapabilitiesState
>();
