import { PatientDetailsResponsesSortCol, SortDir } from '@/shared';
import { action, payload } from 'ts-action';

export const PatientDetailsResponsesFetched = action(
  '[ component | PatientDetailsResponsesShell ] Patient Detail Responses Requested',
);

export const PatientDetailsResponsesTableSorted = action(
  '[ component | PatientDetailsResponsesShell ] Table Sorted',
  payload<{
    col: PatientDetailsResponsesSortCol;
    dir: SortDir;
  }>(),
);

export const PatientDetailsResponsesTablePageUpdated = action(
  '[ component | PatientDetailsResponsesShell ] Page Updated',
  payload<{ page: number }>(),
);
