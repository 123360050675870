import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { capabilitiesProjector } from '@/capabilities/+state/projections-map';
import { LanguagesCapabilities } from '@conversa/bedazzled/src/models/languages-capabilities.interface';

export const loadingCapabilities = capabilitiesProjector<boolean>(
  'capabilities.loading',
  ({ store }) => store.loading,
);

export const caregiverFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.caregiver',
  ({ store }) => store.capabilities.features.caregiver,
);

export const feedbackFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.feedback',
  ({ store }) => store.capabilities.features.feedback,
);

export const patientEditFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.patientEdit',
  ({ store }) => store.capabilities.features.patientEdit,
);

export const demographicCapabilities = capabilitiesProjector<Demographics>(
  'capabilities.demographics',
  ({ store }) => store.capabilities.demographics,
);

export const languageCapabilities = capabilitiesProjector<
  LanguagesCapabilities
>('capabilities.languages', ({ store }) => store.capabilities.languages);
