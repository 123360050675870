



























































import {
  computed,
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import { PreferredCommunicationEventPayload } from '../models/preferred-communication-event.interface';

export default defineComponent({
  props: ['phoneText', 'emailText', 'preference'],
  emits: ['commValues'],
  setup(props, { emit }) {
    const phone = ref(null);
    const email = ref(null);
    const notificationType = ref(props.preference);

    const isPhone = computed(() => notificationType.value !== 'email');

    const isEmail = computed(() => notificationType.value === 'email');

    const phoneRequired = computed(
      () =>
        !!props.phoneText?.length ||
        (!props.phoneText?.length && !props.emailText?.length),
    );

    const preferred = computed(() => {
      if (phone.value?.length && email.value?.length) {
        return notificationType.value || 'sms';
      }

      switch (true) {
        case !!phone.value?.length:
          return 'sms';

        case !!email.value?.length:
          return 'email';

        default:
          return null;
      }
    });

    const watchStopHandler = watchEffect(() => {
      // We do not want to emit empty strings
      // As those have a different meaning for both
      // HTML required attributes and our API
      const payload: PreferredCommunicationEventPayload = {
        phone: phone.value || null,
        email: email.value || null,
        preferred: preferred.value,
      };
      emit('commValues', payload);
    });

    onMounted(() => {
      phone.value = props.phoneText;
      email.value = props.emailText;
    });

    onBeforeUnmount(() => {
      watchStopHandler();
    });

    return {
      phone,
      email,
      phoneRequired,
      notificationType,
      isPhone,
      isEmail,
      normalizeNumber: value => value?.replace(/[^0-9]/g, ''),
    };
  },
});
