import { TableMessage } from './../../shared/empty-table-messages';
import {
  buildFilterPayload,
  formatISODate,
  ActivitiesSortCol,
  SortDir,
} from '@/shared';
import { ErrorState } from '@/shared/models/error-state.interface';
import {
  ActivitiesFilters,
  ActivitiesSearchFiltersPayload,
  ActivitiesTable,
} from '../models';
import { activitiesProjector } from './projections-map';

export const activitiesFilters = activitiesProjector<ActivitiesFilters>(
  'activities.filters',
  ({ store }) => store.filters,
);

export const activitiesLoading = activitiesProjector<boolean>(
  'activities.loading',
  ({ store }) => store.loading,
);

export const activitiesTable = activitiesProjector<ActivitiesTable>(
  'activities.table',
  ({ store }) =>
    store.data.map(d => ({
      activityDate: formatISODate(d.activity_created_at, 'MM/dd/yyyy hh:mm a'),
      patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
      providerName: `${d.provider_first_name} ${d.provider_last_name}`,
      clinicPatientCode: d.clinic_patient_code,
      activity: d.activity,
      patientId: d.patient_id,
    })),
);

export const activitiesSearchFilters = activitiesProjector<
  ActivitiesSearchFiltersPayload
>('activities.search-payload', ({ store, select }) => {
  const map = new Map<keyof ActivitiesFilters, string>([
    ['activityDateFrom', 'activity_date_from'],
    ['activityDateTo', 'activity_date_to'],
    ['clinicPatientCode', 'clinic_patient_code'],
    ['firstName', 'first_name'],
    ['lastName', 'last_name'],
    ['patientStatus', 'patient_status'],
    ['providerIds', 'provider_ids'],
    ['showTestPatients', 'show_test_patients'],
    ['activity', 'activity'],
  ]);

  const payload: ActivitiesSearchFiltersPayload = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    org_id: select('organizations.selected').value.id,
    ...buildFilterPayload<ActivitiesSearchFiltersPayload>(
      store,
      map,
      'activitiesSearchFilters',
    ),
  };

  return payload;
});

export const activitiesError = activitiesProjector<ErrorState>(
  'activities.error',
  ({ store }) => store.error,
);

export const tableMessage = activitiesProjector<TableMessage>(
  'activities.tableMessage',
  ({ store }) => store.tableMessage,
);

export const count = activitiesProjector<number>(
  'activities.count',
  ({ store }) => store.count,
);

export const sortCol = activitiesProjector<ActivitiesSortCol>(
  'activities.sort-col',
  ({ store }) => store.sortCol,
);

export const sortDir = activitiesProjector<SortDir>(
  'activities.sort-dir',
  ({ store }) => store.sortDir,
);

export const filtersDirty = activitiesProjector<boolean>(
  'activities.filters-dirty',
  ({ store }) => store.filtersDirty,
);

export const pageNumber = activitiesProjector<number>(
  'activities.page-number',
  ({ store }) => store.page,
);
