import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=0ee9e47e&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=ts&"
export * from "./Dialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ee9e47e",
  null
  
)

export default component.exports