















import sink from '@/sink';
import { Dialog, DialogActions } from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';
import { LogoutButtonClicked } from '../../+state/events';

export default defineComponent({
  components: {
    Dialog,
    DialogActions,
  },
  setup(_, ctx) {
    return {
      acknowledge: () => ctx.emit('dialog-closed'),
      relogin: sink.lazyBroadcast(
        LogoutButtonClicked({ origin: 'session-expiring' }),
      ),
    };
  },
});
