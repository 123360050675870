











import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { CaregiverCreateSubmitted } from '../+state/events';
import CaregiverDialog from './CaregiverDialog.vue';
import { CaregiverCreateUpdatePayload } from '../models';

export default defineComponent({
  props: ['closeable'],
  components: {
    CaregiverDialog,
  },
  setup() {
    return {
      save(payload: Omit<CaregiverCreateUpdatePayload, 'patientId'>) {
        sink.broadcast(CaregiverCreateSubmitted(payload));
      },
    };
  },
});
