import { ErrorState } from '@/shared';
import { action, payload } from 'ts-action';

export const PatientSessionInformationMounted = action(
  '[ component | Patient Session Information ] Mounted',
);

export const PatientsSessionsFailed = action(
  '[ api | vista/patients/:id/sessions ] Patient Sessions Failed',
  payload<{
    error: ErrorState;
  }>(),
);

export const ViewHistoricUpdated = action(
  '[ component | Patient Detail ] View Historic Updated',
  payload<{ viewHistoric: boolean }>(),
);

export const FetchSessions = action(
  '[ api | vista/patients/:id/sessions ] Fetch Sessions',
);

export const SessionPageUpdated = action(
  '[ component | Patient Detail ] Session Page Updated',
  payload<{ page: number }>(),
);

export const SessionTableUpdated = action(
  '[ component | Patient Detail ] Session Table Updated',
);

export const PatientResendChatLinkRequested = action(
  '[ component | Patient Resend Chat Link Dialog ] Resent Chat Link Requested',
);

export const PatientResendChatLinkRequestQueued = action(
  '[ component | Patient Resend Chat Link Dialog ] Resent Chat Link Request Queued',
);

export const PatientResendChatLinkRequestFailed = action(
  '[ component | Patient Resend Chat Link Dialog ] Resent Chat Link Request Failed',
  payload<{
    error: ErrorState;
  }>(),
);

export const PatientResendChatLinkClicked = action(
  '[ component | Patient Detail Shell ] Resend Chat Link Clicked',
);
