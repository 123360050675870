























import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  language: 'en' | 'es';
  id: string;
  required?: boolean;
}>({
  props: ['language', 'id', 'required'],
  emits: ['change'],
});
