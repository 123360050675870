import { tableMessages } from './../../shared/empty-table-messages';
import { createStoreV2 } from '@conversa/sink';
import { ResponsesState } from '../models';

export const responsesStateKey = 'responses-state';

export const responsesInitialState: ResponsesState = {
  data: [],
  count: null,
  error: null,
  loading: false,
  page: 1,
  sortCol: 'responseDate',
  sortDir: 'desc',
  filtersDirty: false,
  tableMessage: tableMessages.preSearch,
  filters: {
    activeCaregiver: null,
    responseStatus: [],
    responsesDateFrom: '',
    responsesDateTo: '',
    patientStatus: null,
    firstName: null,
    lastName: null,
    language: null,
    question: null,
    clinicPatientCode: null,
    programIds: [],
    providerIds: [],
    showTestPatients: null,
  },
};

export const responsesStore = createStoreV2(
  responsesStateKey,
  responsesInitialState,
);
