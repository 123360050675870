









import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { SurveySelected } from '../+state/events';
import EnrollmentProgramListDialog from '@conversa/bedazzled/src/dialog/enrollments/EnrollmentProgramListDialog.vue';

export default defineComponent({
  components: {
    EnrollmentProgramListDialog,
  },
  setup(_, ctx) {
    return {
      loading: sink.select('patients.detail.summary.enrollments.loading'),
      surveys: sink.select('patients.detail.summary.surveys'),
      cancelDialog: () => ctx.emit('dialog-closed'),
      selectSurvey: surveyId => {
        sink.broadcast(SurveySelected({ id: surveyId }));
      },
    };
  },
});
