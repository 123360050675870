




















import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';

export default defineComponent<{ error: string }>({
  props: ['error'],
  components: { Dialog, DialogContent, DialogActions },
});
