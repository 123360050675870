import { tableMessages } from '../../shared/empty-table-messages';
import { filterInputUpdate, isAxiosError, Payload } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { VistaProjections } from '@/sink';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { PatientEngagementsSearchFiltersPayload } from '../models/patient-engagements-search-payload.interface';
import { PatientEngagementsState } from '../models';
import {
  PatientEngagementsFilterChanged,
  PatientEngagementsFiltersCleared,
  PatientEngagementsFilterSearched,
  PatientEngagementsPageUpdated,
  PatientEngagementsSearchFailed,
  PatientEngagementsSearchFailedDialogDismissed,
  PatientEngagementsTableSorted,
} from './events';
import { OrganizationChanged } from '@/organizations/+state/events';
import { patientEngagementsInitialState } from './store';

export const patientEngagementsFilterChanged = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsFilterChanged>
>({
  sources: [PatientEngagementsFilterChanged],
  sink: filterInputUpdate,
});

const clearFilters = (store: PatientEngagementsState) => {
  store.filters.enrollmentDateFrom =
    patientEngagementsInitialState.filters.enrollmentDateFrom;
  store.filters.enrollmentDateTo =
    patientEngagementsInitialState.filters.enrollmentDateTo;
  store.filters.patientStatus =
    patientEngagementsInitialState.filters.patientStatus;
  store.filters.programIds = patientEngagementsInitialState.filters.programIds;
  store.filters.providerIds =
    patientEngagementsInitialState.filters.providerIds;
  store.filters.showTestPatients =
    patientEngagementsInitialState.filters.showTestPatients;
  store.filters.lastChatStatus =
    patientEngagementsInitialState.filters.lastChatStatus;
};

export const patientEngagementsFiltersCleared = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsFiltersCleared>
>({
  sources: [PatientEngagementsFiltersCleared],
  sink({ store }) {
    clearFilters(store);
    store.filtersDirty = true;
  },
});

export const patientEngagementsFilterSearched = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsFilterSearched>
>({
  sources: [PatientEngagementsFilterSearched],
  async sink({ store, select, broadcast, getStore, event }) {
    const token = getStore('user').accessToken;

    store.filtersDirty = false;
    /* eslint-disable @typescript-eslint/camelcase */

    if (event.payload.resetPage) {
      store.page = 1;
    }

    const payload: Payload<PatientEngagementsSearchFiltersPayload> = {
      pagination: {
        with_count: true,
        page: store.page,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
      },
      filters: select<keyof VistaProjections>(
        'patientEngagement.search-payload',
      ).value,
    };

    try {
      store.loading = true;

      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/non_responders/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        PatientEngagementsSearchFailed({
          payload,
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
    /* eslint-enable @typescript-eslint/camelcase */
  },
});

export const clearErrorState = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsSearchFailedDialogDismissed>
>({
  sources: [PatientEngagementsSearchFailedDialogDismissed],
  sink({ store }) {
    store.error = null;
  },
});

export const clearPatientEngagementState = createSink<
  PatientEngagementsState,
  ReturnType<typeof UserAccessTokenInvalid | typeof OrganizationChanged>
>({
  sources: [UserAccessTokenInvalid, OrganizationChanged],
  async sink({ store }) {
    store.data = patientEngagementsInitialState.data;
    store.count = patientEngagementsInitialState.count;
    store.error = patientEngagementsInitialState.error;
    store.loading = patientEngagementsInitialState.loading;
    store.page = patientEngagementsInitialState.page;
    store.sortCol = patientEngagementsInitialState.sortCol;
    store.sortDir = patientEngagementsInitialState.sortDir;
    store.filtersDirty = patientEngagementsInitialState.filtersDirty;
    store.tableMessage = patientEngagementsInitialState.tableMessage;
    clearFilters(store);
  },
});

export const patientEngagementsTableSortedSink = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsTableSorted>
>({
  sources: [PatientEngagementsTableSorted],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(PatientEngagementsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Sorting now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(
        PatientEngagementsFilterSearched({ origin: 'sort', resetPage: true }),
      );
    }
  },
});
export const patientEngagementsPageUpdated = createSink<
  PatientEngagementsState,
  ReturnType<typeof PatientEngagementsPageUpdated>
>({
  sources: [PatientEngagementsPageUpdated],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.page = event.payload.page;
      broadcast(PatientEngagementsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Changing the page now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.page = event.payload.page;
      broadcast(
        PatientEngagementsFilterSearched({ origin: 'sort', resetPage: true }),
      );
    }
  },
});
