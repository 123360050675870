import { OrganizationChanged } from '@/organizations/+state/events';
import { PatientStatusDialogDismissed } from '@/patients/detail/header/+state/events';
import { CaregiverDialogDismissed } from '@/patients/detail/summary/caregiver/+state/events';
import { EnrollmentDetailsUnmounted } from '@/patients/detail/summary/enrollments/+state/events';
import { filterInputUpdate, isAxiosError, Payload } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { Patient, PatientSearchFiltersPayload, PatientsState } from '../models';
import { tableMessages } from './../../shared/empty-table-messages';
import * as events from './events';
import { patientsInitialState } from './store';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export const patientFilterChanged = createSink<
  PatientsState,
  ReturnType<typeof events.PatientsFilterChanged>
>({
  sources: [events.PatientsFilterChanged],
  sink: filterInputUpdate,
});

export const patientAdded = createSink<
  PatientsState,
  ReturnType<
    | typeof events.AddPatientDialogSave
    | typeof events.AddPatientDialogSaveBeforeEnroll
  >
>({
  sources: [
    events.AddPatientDialogSave,
    events.AddPatientDialogSaveBeforeEnroll,
  ],
  async sink({ event, broadcast, store, getStore }) {
    store.loading = true;
    const token = getStore('user').accessToken;
    let newPatient: Patient;

    try {
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/patients',
        event.payload,
      );

      newPatient = data;
      store.newlyAddedPatient = data;
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        console.warn(error);
        return;
      }

      if (error.response?.status !== 401) {
        // [dw] 2021-06-25 - this is commented out until we address backend sending validations
        //
        // if (error.response?.status === 400) {
        //   broadcast(
        //     NewPatientInvalid({
        //       payload: event.payload,
        //       status: error.response.status,
        //       errors: error.response.data.details?.errors,
        //     }),
        //   );
        // } else {
        broadcast(
          events.NewPatientFailed({
            payload: event.payload,
            status: error.response.status,
            message: error.response.data,
          }),
        );
        // }

        return;
      }
    } finally {
      store.loading = false;
    }
    /* eslint-enable @typescript-eslint/camelcase */

    switch (event.type) {
      case events.AddPatientDialogSave.type:
        broadcast(events.NewPatientSaved(newPatient));
        break;

      case events.AddPatientDialogSaveBeforeEnroll.type:
        broadcast(events.NewPatientSavedBeforeEnroll(newPatient));
        break;

      default:
        break;
    }
  },
});

const clearFilters = (store: PatientsState) => {
  store.filters.activeCaregiver = patientsInitialState.filters.activeCaregiver;
  store.filters.clinicPatientCode =
    patientsInitialState.filters.clinicPatientCode;
  store.filters.createdAtFrom = patientsInitialState.filters.createdAtFrom;
  store.filters.createdAtTo = patientsInitialState.filters.createdAtTo;
  store.filters.email = patientsInitialState.filters.email;
  store.filters.firstName = patientsInitialState.filters.firstName;
  store.filters.language = patientsInitialState.filters.language;
  store.filters.lastName = patientsInitialState.filters.lastName;
  store.filters.patientStatus = patientsInitialState.filters.patientStatus;
  store.filters.phone = patientsInitialState.filters.phone;
  store.filters.searchSubOrgs = patientsInitialState.filters.searchSubOrgs;
  store.filters.providerIds = patientsInitialState.filters.providerIds;
};

export const patientsFilterCleared = createSink<
  PatientsState,
  ReturnType<typeof events.PatientsFiltersCleared>
>({
  sources: [events.PatientsFiltersCleared],
  sink({ store }) {
    clearFilters(store);
    store.filtersDirty = true;
  },
});

export const patientsFilterSearched = createSink<
  PatientsState,
  ReturnType<typeof events.PatientsFilterSearched>
>({
  sources: [events.PatientsFilterSearched],
  async sink({ store, select, broadcast, getStore, event }) {
    const token = getStore('user').accessToken;

    store.filtersDirty = false;

    if (event.payload.resetPage) {
      store.page = 1;
    }

    const payload: Payload<PatientSearchFiltersPayload> = {
      pagination: {
        /* eslint-disable @typescript-eslint/camelcase */
        with_count: true,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
        /* eslint-enable @typescript-eslint/camelcase */
        page: store.page,
      },
      filters: select('patients.search-payload').value,
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/patients/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      if (error.response?.status !== 401) {
        broadcast(
          events.PatientsSearchFailed({
            payload,
            status: error.response.status,
            message: error.response.data,
          }),
        );
      }
    }
  },
});

export const clearErrorState = createSink<
  PatientsState,
  ReturnType<
    | typeof events.PatientSearchFailedDialogDismissed
    | typeof PatientStatusDialogDismissed
    | typeof EnrollmentDetailsUnmounted
  >
>({
  sources: [
    events.PatientSearchFailedDialogDismissed,
    PatientStatusDialogDismissed,
    EnrollmentDetailsUnmounted,
    CaregiverDialogDismissed,
  ],
  sink({ store }) {
    store.error = null;
  },
});

export const clearPatientsState = createSink<
  PatientsState,
  ReturnType<typeof UserAccessTokenInvalid | typeof OrganizationChanged>
>({
  sources: [UserAccessTokenInvalid, OrganizationChanged],
  async sink({ store }) {
    store.data = patientsInitialState.data;
    store.count = patientsInitialState.count;
    store.error = patientsInitialState.error;
    store.loading = patientsInitialState.loading;
    store.page = patientsInitialState.page;
    store.sortCol = patientsInitialState.sortCol;
    store.sortDir = patientsInitialState.sortDir;
    store.filtersDirty = patientsInitialState.filtersDirty;
    store.tableMessage = patientsInitialState.tableMessage;
    clearFilters(store);
    store.newlyAddedPatient = patientsInitialState.newlyAddedPatient;
  },
});

export const patientsTableSortedSink = createSink<
  PatientsState,
  ReturnType<typeof events.PatientsTableSorted>
>({
  sources: [events.PatientsTableSorted],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(events.PatientsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Sorting now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(
        events.PatientsFilterSearched({ origin: 'sort', resetPage: true }),
      );
    }
  },
});

export const patientsPageUpdated = createSink<
  PatientsState,
  ReturnType<typeof events.PatientsPageUpdated>
>({
  sources: [events.PatientsPageUpdated],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.page = event.payload.page;
      broadcast(events.PatientsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Changing the page now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.page = event.payload.page;
      broadcast(
        events.PatientsFilterSearched({ origin: 'sort', resetPage: true }),
      );
    }
  },
});
