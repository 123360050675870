








import sink from '@/sink';
import ResendChatDialog from '@conversa/bedazzled/src/dialog/chats/ResendChatDialog.vue';
import { defineComponent } from '@vue/composition-api';
import { PatientResendChatLinkRequested } from './+state/events';
import { patientsDetailSummarySessionsModule } from './+state/module';

export default defineComponent({
  components: { ResendChatDialog },
  setup(_, ctx) {
    return {
      errorMessage:
        patientsDetailSummarySessionsModule.injectStore().error?.message || '',
      resend: sink.lazyBroadcast(PatientResendChatLinkRequested()),
      cancel: () => ctx.emit('dialog-closed'),
    };
  },
});
