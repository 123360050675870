




import * as patientEvents from '@/patients/+state/events';
import * as patientDetailHeaderEvents from '@/patients/detail/header/+state/events';
import PatientStatusUpdateDialog from '@/patients/detail/header/PatientStatusUpdateDialog.vue';
import * as caregiverEvents from '@/patients/detail/summary/caregiver/+state/events';
import * as careGiverDialogs from '@/patients/detail/summary/caregiver/dialogs';
import * as enrollmentEvents from '@/patients/detail/summary/enrollments/+state/events';
import * as enrollmentDialogs from '@/patients/detail/summary/enrollments/dialogs';
import * as profileEvents from '@/patients/detail/summary/profile/+state/events';
import * as sessionEvents from '@/patients/detail/summary/sessions/+state/events';
import PatientResendChatLinkDialog from '@/patients/detail/summary/sessions/PatientResendChatLinkDialog.vue';
import * as patientDialogs from '@/patients/dialogs';
import * as reportingEvents from '@/reporting/+state/events';
import ReportLoadFailedDialog from '@/reporting/dialogs/ReportLoadFailed.vue';
import ReportsAvailableLoadFailedDialog from '@/reporting/dialogs/ReportsAvailableLoadFailed.vue';
import { RouteChanged } from '@/router/+state';
import sink from '@/sink';
import * as userEvents from '@/user/+state/events';
import { UserTokenInvalidDialog } from '@/user/dialogs';
import UserProfileDialog from '@/user/dialogs/profile/ProfileDialog.vue';
import ProfileFetchFailedDialog from '@/user/dialogs/profile/ProfileFetchFailedDialog.vue';
import InactivityWarningDialog from '@/user/dialogs/timeouts/InactivityWarningDialog.vue';
import SessionEndingSoonDialog from '@/user/dialogs/timeouts/SessionEndingSoonDialog.vue';
import MiscExportFailedDialog from '@/misc-dialogs/MiscExportFailedDialog.vue';
import DeeplinkFetchFailedDialog from '@/misc-dialogs/MiscDeeplinkFetchFailed.vue';
import { DialogContainer } from '@conversa/bedazzled/src/dialog';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { merge, Subject } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { ofType } from 'ts-action-operators';
import * as miscEvents from '@/+state/events';

export default defineComponent({
  components: { DialogContainer },
  setup() {
    const dialog = ref();
    // prettier-ignore
    const dialogMap = {
      [caregiverEvents.CaregiverStatusClicked.type]: careGiverDialogs.CaregiverStatusDialog,
      [enrollmentEvents.ReenrollRequested.type]: enrollmentDialogs.EnrollmentCreateDialog,
      [enrollmentEvents.EnrollmentCreateDialogBackClicked.type]: enrollmentDialogs.EnrollmentProgramListDialog,
      [enrollmentEvents.EnrollmentEditClicked.type]: enrollmentDialogs.EnrollmentUpdateDialog,
      [patientEvents.NewPatientSavedBeforeEnroll.type]: enrollmentDialogs.EnrollmentProgramListDialog,
      [enrollmentEvents.EnrollPatientClicked.type]: enrollmentDialogs.EnrollmentProgramListDialog,
      [sessionEvents.PatientResendChatLinkClicked.type]: PatientResendChatLinkDialog,
      [profileEvents.PatientsDetailsFailed.type]: patientDialogs.PatientSearchFailedDialog,
      [patientEvents.PatientsSearchFailed.type]: patientDialogs.PatientSearchFailedDialog,
      [patientDetailHeaderEvents.PatientStatusClicked.type]: PatientStatusUpdateDialog,
      [enrollmentEvents.SurveySelected.type]: enrollmentDialogs.EnrollmentCreateDialog,
      [userEvents.InactivityWarningThresholdMet.type]: InactivityWarningDialog,
      [userEvents.ProfileButtonClicked.type]: UserProfileDialog,
      [userEvents.ProfileFetchFailed.type]: ProfileFetchFailedDialog,
      [userEvents.SessionEndingSoonThresholdMet.type]: SessionEndingSoonDialog,
      [userEvents.UserAccessTokenInvalid.type]: UserTokenInvalidDialog,
      [reportingEvents.ReportLoadFailed.type]: ReportLoadFailedDialog,
      [reportingEvents.ReportsAvailableLoadFailed.type]: ReportsAvailableLoadFailedDialog,
      [miscEvents.ExportFailed.type]: MiscExportFailedDialog,
      [miscEvents.DeeplinkFetchFailed.type]: DeeplinkFetchFailedDialog,
      [miscEvents.DeeplinkValidationFailed.type]: DeeplinkFetchFailedDialog,
    };

    const unclosableDialogs = [userEvents.UserAccessTokenInvalid.type];

    const dialogClosedEmits$ = new Subject<boolean>();

    const dialogClosed$ = merge(
      sink.events$.pipe(
        ofType(
          caregiverEvents.CaregiverCreated,
          caregiverEvents.CaregiverUpdated,
          caregiverEvents.CaregiverDialogDismissed,
          enrollmentEvents.EnrollmentChangeStatusSucceeded,
          enrollmentEvents.EnrollmentUpdateSucceeded,
          enrollmentEvents.PatientEnrollmentSuccessful,
          profileEvents.PatientEdited,
          patientEvents.NewPatientSaved,
          patientEvents.PatientSearchFailedDialogDismissed,
          patientDetailHeaderEvents.PatientStatusChanged,
          patientDetailHeaderEvents.PatientStatusDialogDismissed,
          sessionEvents.PatientResendChatLinkRequestQueued,
          RouteChanged,
          userEvents.ProfileFetchErrorDialogDismissed,
          userEvents.ProfileUpdated,
          miscEvents.ExportFailedDismissDialog,
          miscEvents.DeeplinkFetchFailedDismissDialog,
        ),
      ),
      dialogClosedEmits$,
    ).pipe(
      // Some dialogs should not close on route change, such as UserAccessTokenInvalid
      filter(() => !unclosableDialogs.includes(dialog.value)),
      mapTo(null),
    );

    const dialogOpened$ = sink.events$.pipe(
      ofType(
        caregiverEvents.CaregiverStatusClicked,
        enrollmentEvents.ReenrollRequested,
        enrollmentEvents.EnrollmentCreateDialogBackClicked,
        enrollmentEvents.EnrollmentEditClicked,
        enrollmentEvents.EnrollPatientClicked,
        patientEvents.NewPatientSavedBeforeEnroll,
        sessionEvents.PatientResendChatLinkClicked,
        profileEvents.PatientsDetailsFailed,
        patientEvents.PatientsSearchFailed,
        patientDetailHeaderEvents.PatientStatusClicked,
        enrollmentEvents.SurveySelected,
        reportingEvents.ReportLoadFailed,
        reportingEvents.ReportsAvailableLoadFailed,
        userEvents.InactivityWarningThresholdMet,
        userEvents.ProfileButtonClicked,
        userEvents.ProfileFetchFailed,
        userEvents.SessionEndingSoonThresholdMet,
        userEvents.UserAccessTokenInvalid,
        miscEvents.ExportFailed,
        miscEvents.DeeplinkFetchFailed,
        miscEvents.DeeplinkValidationFailed,
      ),
      map(event => event.type),
    );

    merge(dialogClosed$, dialogOpened$).subscribe(signal => {
      dialog.value = signal;
    });

    return {
      dialog: computed(() => dialogMap[dialog.value]),
      onClose: () => dialogClosedEmits$.next(),
    };
  },
});
