

















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['dialog', 'opts'],
  setup(props) {
    return {
      open: computed(() => !!props.dialog),
      closeable: computed(() => props.opts?.closeable ?? true),
      persistent: computed(() => props.opts?.persistent ?? true),
    };
  },
});
