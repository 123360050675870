

























import { defineComponent, ref } from '@vue/composition-api';
import AncestryOption from './AncestryOption.vue';

export default defineComponent({
  name: 'AncestryOption',
  props: ['options', 'set'],
  emits: ['selected'],
  components: { AncestryOption },
  setup(props, { emit }) {
    const open = ref([]);
    return {
      open,
      handleOpen: id => {
        open.value.includes(id)
          ? open.value.splice(open.value.indexOf(id))
          : open.value.push(id);
      },
      selected: id => {
        emit('selected', id);
      },
    };
  },
});
