var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":[
    {
      value: 'notification_sent',
      text: 'Notification Sent',
    },
    {
      value: 'notification_opened',
      text: 'Notification Opened',
    },
    {
      value: 'invitation_sent',
      text: 'Invitation Sent',
    },
    {
      value: 'invitation_opened',
      text: 'Invitation Opened',
    },
    {
      value: 'reminder_sent',
      text: 'Reminder Sent',
    },
    {
      value: 'reminder_opened',
      text: 'Reminder Opened',
    },
    {
      value: 'enrolled',
      text: 'Enrolled',
    },
    {
      value: 'opted_out',
      text: 'Opted Out',
    },
    {
      value: 'survey_started',
      text: 'Module Started',
    },
    {
      value: 'survey_completed',
      text: 'Module Completed',
    },
    {
      value: 'survey_abandoned',
      text: 'Module Abandoned',
    },
    {
      value: 'rejected',
      text: 'Rejected',
    },
    {
      value: 'survey_patient_completed',
      text: 'Module Patient Completed',
    },
    {
      value: 'survey_system_completed',
      text: 'Module System Completed',
    },
    {
      value: 'survey_ignored',
      text: 'Module Ignored',
    },
    {
      value: 'opted_out_by_email_link',
      text: 'Opted Out by Email Link',
    },
    {
      value: 'opted_out_by_email_bounce',
      text: 'Opted Out by Email Bounce',
    },
    {
      value: 'opted_out_by_preferences',
      text: 'Opted Out through Patient Preferences',
    },
    {
      value: 'opted_out_by_sms_stop',
      text: 'Opted Out by SMS Stop',
    },
    {
      value: 'opted_out_by_termination',
      text: 'Opted Out by Termination',
    },
    {
      value: 'opted_out_by_provider',
      text: 'Opted Out by Provider',
    },
    {
      value: 'opted_in',
      text: 'Opted In',
    },
    {
      value: 'opted_out_by_terms_rejection',
      text: 'Opted Out by Terms Rejection',
    },
    {
      value: 'phone_number_click',
      text: 'Phone Number Clicked',
    },
    {
      value: 'notification_error',
      text: 'SMS Notification Error',
    },
    {
      value: 'link_click',
      text: 'Link Clicked',
    } ],"value":_vm.activity,"id":_vm.id,"name":"activity","label":"Activity","placeholder":"--","clearable":""},on:{"change":function($event){return _vm.$emit('change', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }