/* eslint-disable @typescript-eslint/camelcase */
import { tableMessages } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { createSink } from '@conversa/sink';
import { PatientDetailFetched } from '../../+state/events';
import { PatientDetailsResponsesState } from '../../models';
import {
  PatientDetailsResponsesTablePageUpdated,
  PatientDetailsResponsesTableSorted,
  PatientDetailsResponsesFetched,
} from './events';

export const patientDetailsResponsesRequested = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesFetched>
>({
  sources: [PatientDetailsResponsesFetched, PatientDetailFetched],
  async sink({ store, broadcast, getStore }) {
    const router = getStore('router');
    const token = getStore('user').accessToken;
    const orgId = getStore('organizations-state').selectedOrgId;
    const clinicPatientCode = getStore('patients-detail').data
      ?.clinic_patient_code;

    if (!clinicPatientCode || router.route?.name !== 'patientResponses') return;

    const payload = {
      filters: {
        org_id: orgId,
        clinic_patient_code: clinicPatientCode,
      },
      pagination: {
        with_count: true,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
        page: store.page,
      },
    };

    try {
      store.loading = true;

      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/responses/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
    } catch (error) {
      store.data = [];
      store.tableMessage = tableMessages.serverError;
    } finally {
      store.loading = false;
    }
  },
});

export const patientDetailsResponsesTableSorted = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesTableSorted>
>({
  sources: [PatientDetailsResponsesTableSorted],
  sink({ store, event, broadcast }) {
    store.sortCol = event.payload.col;
    store.sortDir = event.payload.dir;
    store.page = 1;
    broadcast(PatientDetailsResponsesFetched());
  },
});

export const patientDetailsResponsesPageUpdated = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesTablePageUpdated>
>({
  sources: [PatientDetailsResponsesTablePageUpdated],
  sink({ store, event, broadcast }) {
    store.page = event.payload.page;
    broadcast(PatientDetailsResponsesFetched());
  },
});
