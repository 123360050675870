






















































































































import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  activity:
    | 'notification-sent'
    | 'notification-opened'
    | 'invitation-sent'
    | 'invitation-opened'
    | 'reminder-sent'
    | 'reminder-opened'
    | 'enrolled'
    | 'opted-out'
    | 'survey-started'
    | 'survey-completed'
    | 'survey-abandoned'
    | 'rejected'
    | 'survey-patient-completed'
    | 'survey-system-completed'
    | 'survey-ignored'
    | 'opted-out-by-email-link'
    | 'opted-out-by-email-bounce'
    | 'opted-out-by-preferences'
    | 'opted-out-by-sms-stop'
    | 'opted-out-by-termination'
    | 'opted-out-by-provider'
    | 'opted-in'
    | 'opted-out-by-terms-rejection'
    | 'phone-number-click'
    | 'notification-error'
    | 'link-click';
  id: string;
}>({
  props: ['activity', 'id'],
});
