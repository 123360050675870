import { createStoreV2 } from '@conversa/sink';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';

export const capabilitiesStateKey = 'capabilities-state';

export const capabilitiesInitialState: CapabilitiesState = {
  capabilities: {
    access: {},
    demographics: {
      dateOfBirthEnabled: false,
      genderEnabled: false,
      patientIdCreationEnabled: false,
    },
    features: {
      caregiver: false,
      feedback: false,
      patientEdit: false,
    },
    languages: { spanishEnabled: false },
  },
  loading: false,
};

export const capabilitiesStore = createStoreV2(
  capabilitiesStateKey,
  capabilitiesInitialState,
);
