import { Capabilities } from '@conversa/bedazzled/src/models/capabilities.interface';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
import {
  OrganizationChanged,
  OrganizationsLoaded,
} from '@/organizations/+state/events';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { CapabilitiesLoaded, CapabilitiesLoadFailed } from './events';
import { capabilitiesInitialState } from './store';

export const loadCapabilities = createSink<
  CapabilitiesState,
  ReturnType<typeof OrganizationChanged | typeof OrganizationsLoaded>
>({
  sources: [OrganizationsLoaded, OrganizationChanged],
  async sink({ store, getStore, broadcast }) {
    const token = getStore('user').accessToken;
    const selectedOrgId = getStore('organizations-state').selectedOrgId;
    store.loading = true;

    try {
      const capabilities = (
        await axiosWithAuth(token, broadcast).get<Capabilities>(
          `/api/vista/access/capabilities/${selectedOrgId}`,
          {},
        )
      ).data;

      store.capabilities = capabilities;
      broadcast(CapabilitiesLoaded(capabilities));
      store.loading = false;
    } catch (error) {
      store.loading = false;

      if (error.response?.status !== 401)
        broadcast(
          CapabilitiesLoadFailed({
            status: error.response?.status,
            message: error.message,
          }),
        );
    }
  },
});

export const clearCapabilitiesState = createSink<
  CapabilitiesState,
  ReturnType<typeof UserAccessTokenInvalid>
>({
  sources: [UserAccessTokenInvalid],
  sink({ store }) {
    store.capabilities.access = capabilitiesInitialState.capabilities.access;
    store.capabilities.demographics.dateOfBirthEnabled =
      capabilitiesInitialState.capabilities.demographics.dateOfBirthEnabled;
    store.capabilities.demographics.genderEnabled =
      capabilitiesInitialState.capabilities.demographics.genderEnabled;
    store.capabilities.demographics.patientIdCreationEnabled =
      capabilitiesInitialState.capabilities.demographics.patientIdCreationEnabled;
    store.capabilities.languages.spanishEnabled =
      capabilitiesInitialState.capabilities.languages.spanishEnabled;
    store.loading = capabilitiesInitialState.loading;
  },
});
