




import { ErrorDialog } from '@/shared/components';
import sink from '@/sink';
import { ProfileFetchErrorDialogDismissed, userModule } from '@/user/+state';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      error: userModule.injectStore().error,
      dismissed: sink.lazyBroadcast(ProfileFetchErrorDialogDismissed()),
    };
  },
});
