import { formatISODate, FeedbackSortCol, SortDir } from '@/shared';
import { feedbackProjector } from './projections-map';
import { FeedbackTable, FeedbackFiltersPayload } from '../models';

export const feedbackTable = feedbackProjector<FeedbackTable>(
  'feedback.table',
  ({ store }) =>
    store.data.map(d => ({
      clinicPatientCode: d.clinic_patient_code,
      feedback: d.feedback,
      feedbackDate: formatISODate(d.created_at_date, 'MM/dd/yyyy hh:mm a'),
      feedbackId: d.id,
      patientId: d.patient_id,
      patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
      feedbackProvider: `${d.provider_first_name} ${d.provider_last_name}`,
    })),
);

export const feedbackSearchPayload = feedbackProjector<FeedbackFiltersPayload>(
  'feedback.search-payload',
  ({ select }) => {
    const payload: FeedbackFiltersPayload = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      org_id: select('organizations.selected').value?.id,
    };

    return payload;
  },
);

export const feedbackLoading = feedbackProjector<boolean>(
  'feedback.loading',
  ({ store }) => store.loading,
);

export const count = feedbackProjector<number>(
  'feedback.count',
  ({ store }) => store.count,
);
export const sortCol = feedbackProjector<FeedbackSortCol>(
  'feedback.sort-col',
  ({ store }) => store.sortCol,
);
export const sortDir = feedbackProjector<SortDir>(
  'feedback.sort-dir',
  ({ store }) => store.sortDir,
);
export const pageNumber = feedbackProjector<number>(
  'feedback.page-number',
  ({ store }) => store.page,
);
