var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":[
    {
      value: 'en',
      text: 'English',
    },
    {
      value: 'es',
      text: 'Spanish',
    } ],"value":_vm.language,"id":_vm.id,"required":_vm.required,"name":"preferred-language","label":"Preferred Language","placeholder":"--","clearable":!_vm.required},on:{"change":function($event){return _vm.$emit('change', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }