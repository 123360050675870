








































import {
  defineComponent,
  UnwrapRef,
  reactive,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import EnrollmentDetailsDialog from '@conversa/bedazzled/src/dialog/enrollments/EnrollmentDetailsDialog.vue';
import Vue, { VueConstructor } from 'vue';
import sink from '@/sink';
import {
  EnrollmentCreateDialogBackClicked,
  EnrollmentDetailsUnmounted,
} from '../+state/events';
import { SurveyConceptType } from '@/shared';
import DatePicker from '@/shared/components/DatePicker.vue';
import { formRules } from '@/shared/form-rules';

interface LocalConcept {
  id: number;
  component: VueConstructor<Vue> | string;
  required: boolean;
  content?: string;
  value?: string | number | number[];
  options?: { value: number; content: string }[];
  text?: string;
  type?: SurveyConceptType;
  placeholder?: string;
  legend?: string;
  multiple?: boolean;
}

const inputMap = {
  mult: 'v-select',
  checks: 'v-select',
  email: 'v-text-field',
  float: 'v-text-field',
  int: 'v-text-field',
  result: 'v-text-field',
  phone: 'v-text-field',
  text: 'v-text-field',
  date: DatePicker,
};

export default defineComponent({
  props: ['title', 'successButtonCopy', 'showBackButton', 'loadingCopy'],
  emits: ['enroll'],
  components: {
    EnrollmentDetailsDialog,
  },
  setup(_, context) {
    const error = sink.select('patients.detail.summary.enrollments.error');
    const program = sink.select('patients.detail.summary.selected-survey')
      .value;
    const loading = sink.select('patients.detail.summary.enrollments.loading');

    onUnmounted(sink.lazyBroadcast(EnrollmentDetailsUnmounted()));

    const concepts: UnwrapRef<LocalConcept>[] = program.concepts.map(
      concept => {
        const conceptRules = [];

        switch (concept.type) {
          case 'email':
            conceptRules.push(formRules.emailVal);
            break;
          case 'phone':
            conceptRules.push(formRules.phoneLength);
            break;
          default:
            break;
        }
        if (concept.required) {
          conceptRules.push(formRules.required);
        }

        switch (concept.type) {
          case 'mult':
          case 'checks': {
            return reactive({
              id: concept.id,
              type: concept.type,
              text: concept.text,
              required: concept.required,
              clearable: !concept.required,
              rules: conceptRules,
              component: inputMap[concept.type] || 'v-text-field',
              value: concept.value,
              placeholder: 'Please Select',
              multiple: concept.type === 'checks',
              items: concept.options.map(option => ({
                value: option.id,
                text: option.content,
              })),
            });
          }

          default: {
            return reactive({
              id: concept.id,
              type:
                (['float', 'int'].includes(concept.type) && 'number') ||
                concept.type,
              text: concept.text,
              required: concept.required,
              rules: conceptRules,
              component: inputMap[concept.type] || 'v-text-field',
              value: concept.value,
              content: concept.text,
              step: concept.type === 'float' && 'any',
            });
          }
        }
      },
    );

    const onInputChanged = (event: { id: number; value: string }) => {
      const concept = concepts.find(c => c.id === event.id);
      concept.value = event.value; // this is utilized by inputs like text
    };

    const enroll = () => {
      context.emit('enroll', {
        authoringId: program.authoring_id,
        concepts: concepts
          .filter(concept => concept.value !== null) // empty strings, 0, negative Numbers.. all valid responses
          .map(concept => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let value: any = concept.value;

            // 'checks' is special, as of 6/18/21 existing backend code still requires this during a validation step
            if (concept.type === 'checks' && Array.isArray(concept.value)) {
              value = concept.value.reduce((acc, conceptId) => {
                acc[conceptId] = 'on';
                return acc;
              }, {});
            }

            return {
              id: concept.id,
              value,
            };
          }),
      });
    };

    const isFormValid = ref(false);

    const onIsFormValid = val => (isFormValid.value = val);

    return {
      isFormValid,
      onIsFormValid,
      programName: program.name,
      concepts,
      error,
      loading,
      onInputChanged,
      enroll,
      back: sink.lazyBroadcast(EnrollmentCreateDialogBackClicked()),
      cancel: () => context.parent.$emit('dialog-closed'),
    };
  },
});
