<template>
  <button data-cy="account-button-tag" class="profile" @click="profileClicked">
    <p v-if="label">{{ label }}</p>
    <p v-else-if="user.nameLast">{{ user.nameLast }}, {{ user.nameFirst }}</p>
    <p v-else>-</p>
    <i class="material-icons">account_circle</i>
  </button>
</template>

<script>
import sink from '@/sink';
import { ProfileButtonClicked, userModule } from '@/user';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      profileClicked: sink.lazyBroadcast(ProfileButtonClicked()),
      user: userModule.injectStore(),
    };
  },
});
</script>

<style></style>
