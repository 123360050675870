import {
  formatISODate,
  PatientDetailsResponsesSortCol,
  SortDir,
  TableMessage,
} from '@/shared';
import { createProjector } from '@conversa/sink';
import { PatientDetailsResponsesState } from '../../models';
import { PatientDetailsResponsesTable } from '../../models/patient-details-responses-table.interface';

export interface PatientDetailsResponsesProjectionMap {
  'patient.details.responses.table-message': TableMessage;
  'patient.details.responses.table': PatientDetailsResponsesTable;
  'patient.details.responses.count': number;
  'patient.details.responses.sort-col': PatientDetailsResponsesSortCol;
  'patient.details.responses.sort-dir': SortDir;
  'patient.details.responses.page-number': number;
  'patient.details.responses.loading': boolean;
}

const projector = createProjector<
  PatientDetailsResponsesProjectionMap,
  PatientDetailsResponsesState
>();

export const patientDetailsResponsesTable = projector<
  PatientDetailsResponsesTable
>('patient.details.responses.table', ({ store }) =>
  store.data.map(response => ({
    id: response.id,
    responseDate: formatISODate(
      response.response_created_at,
      'MM/dd/yyyy hh:mm a',
    ),
    programModule: response.survey_name,
    question: response.question_text,
    response: response.response_text,
    responseStatus: response.response_status,
  })),
);

export const patientDetailsResponsesLoading = projector<boolean>(
  'patient.details.responses.loading',
  ({ store }) => store.loading,
);

export const patientDetailsResponsesTableMessage = projector<TableMessage>(
  'patient.details.responses.table-message',
  ({ store }) => store.tableMessage,
);

export const patientDetailsResponsesCount = projector<number>(
  'patient.details.responses.count',
  ({ store }) => store.count,
);

export const patientDetailsResponsesSortCol = projector<
  PatientDetailsResponsesSortCol
>('patient.details.responses.sort-col', ({ store }) => store.sortCol);

export const patientDetailsResponsesSortDir = projector<SortDir>(
  'patient.details.responses.sort-dir',
  ({ store }) => store.sortDir,
);

export const patientDetailsResponsesPageNumber = projector<number>(
  'patient.details.responses.page-number',
  ({ store }) => store.page,
);
