import { TableMessage } from '../../shared/empty-table-messages';
import {
  buildFilterPayload,
  formatISODate,
  ErrorState,
  PatientEngagementsSortCol,
  SortDir,
  humanReadableChatStatus,
  ChatStatus,
} from '@/shared';
import {
  PatientEngagementsFilters,
  PatientEngagementTable,
  PatientEngagementsSearchFiltersPayload,
} from '../models';
import { patientEngagementProjector } from './projections-map';

export const patientEngagementFilters = patientEngagementProjector<
  PatientEngagementsFilters
>('patientEngagement.filters', ({ store }) => store.filters);

export const patientEngagementTable = patientEngagementProjector<
  PatientEngagementTable
>('patientEngagement.table', ({ store }) =>
  store.data.map(d => ({
    chatsIgnored: d.no_response_count.toString(),
    clinicPatientCode: d.clinic_patient_code,
    enrollmentDate: formatISODate(d.enrollment_created_at),
    patientId: d.id,
    lastScheduledChatDate: !d.last_scheduled_chat_date
      ? 'None'
      : formatISODate(d.last_scheduled_chat_date),
    lastChatStatus: !d.last_chat_status
      ? 'None'
      : humanReadableChatStatus(d.last_chat_status as ChatStatus),
    nextScheduledChatDate: !d.next_scheduled_chat_date
      ? 'None'
      : formatISODate(d.next_scheduled_chat_date),
    patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
    program: d.chat_name,
  })),
);

export const patientEngagementSearchFilters = patientEngagementProjector<
  PatientEngagementsSearchFiltersPayload
>('patientEngagement.search-payload', ({ store, select }) => {
  const map = new Map<keyof PatientEngagementsFilters, string>([
    ['enrollmentDateFrom', 'enrollment_date_from'],
    ['enrollmentDateTo', 'enrollment_date_to'],
    ['lastChatStatus', 'last_chat_status'],
    ['patientStatus', 'patient_status'],
    ['programIds', 'program_ids'],
    ['providerIds', 'provider_ids'],
    ['showTestPatients', 'show_test_patients'],
  ]);

  const payload: PatientEngagementsSearchFiltersPayload = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    org_id: select('organizations.selected').value.id,
    ...buildFilterPayload<PatientEngagementsSearchFiltersPayload>(
      store,
      map,
      'patientEngagementSearchFilters',
    ),
  };

  return payload;
});

export const patientEngagementError = patientEngagementProjector<ErrorState>(
  'patientEngagement.error',
  ({ store }) => store.error,
);

export const tableMessage = patientEngagementProjector<TableMessage>(
  'patientEngagement.tableMessage',
  ({ store }) => store.tableMessage,
);

export const count = patientEngagementProjector<number>(
  'patientEngagement.count',
  ({ store }) => store.count,
);
export const loading = patientEngagementProjector<boolean>(
  'patientEngagement.loading',
  ({ store }) => store.loading,
);
export const sortCol = patientEngagementProjector<PatientEngagementsSortCol>(
  'patientEngagement.sort-col',
  ({ store }) => store.sortCol,
);
export const sortDir = patientEngagementProjector<SortDir>(
  'patientEngagement.sort-dir',
  ({ store }) => store.sortDir,
);
export const filtersDirty = patientEngagementProjector<boolean>(
  'patientEngagement.filters-dirty',
  ({ store }) => store.filtersDirty,
);
export const pageNumber = patientEngagementProjector<number>(
  'patientEngagement.page-number',
  ({ store }) => store.page,
);
