




















import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@conversa/bedazzled/src/dialog/index';

export default defineComponent({
  components: { Dialog, DialogContent, DialogActions },
  setup(_, ctx) {
    return {
      error: sink.select('report.error-state'),
      dismissed: () => ctx.emit('dialog-closed'),
    };
  },
});
