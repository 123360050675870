import { OrganizationChanged } from '@/organizations/+state/events';
import { filterInputUpdate, isAxiosError, Payload } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { downloadFromApi } from '@/shared/download-from-api';
import { tableMessages } from '@/shared/empty-table-messages';
import { sortColMap } from '@/shared/sort-col-map';
import { VistaProjections } from '@/sink';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { ResponsesState } from '../models';
import { ResponsesSearchFiltersPayload } from './../models/responses-search-payload.interface';
import {
  ResponsesExportClicked,
  ResponsesExportFailed,
  ResponsesFilterChanged,
  ResponsesFiltersCleared,
  ResponsesFilterSearched,
  ResponsesPageUpdated,
  ResponsesSearchFailed,
  ResponsesSearchFailedDialogDismissed,
  ResponsesTableSorted,
  ViewAllResponsesClicked,
} from './events';
import { responsesInitialState } from './store';

export const responsesFilterChanged = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesFilterChanged>
>({
  sources: [ResponsesFilterChanged],
  sink: filterInputUpdate,
});

const clearFilters = (store: ResponsesState) => {
  store.filters.activeCaregiver = responsesInitialState.filters.activeCaregiver;
  store.filters.firstName = responsesInitialState.filters.firstName;
  store.filters.language = responsesInitialState.filters.language;
  store.filters.lastName = responsesInitialState.filters.lastName;
  store.filters.patientStatus = responsesInitialState.filters.patientStatus;
  store.filters.programIds = responsesInitialState.filters.programIds;
  store.filters.providerIds = responsesInitialState.filters.providerIds;
  store.filters.question = responsesInitialState.filters.question;
  store.filters.responsesDateFrom =
    responsesInitialState.filters.responsesDateFrom;
  store.filters.responsesDateTo = responsesInitialState.filters.responsesDateTo;
  store.filters.clinicPatientCode =
    responsesInitialState.filters.clinicPatientCode;
  store.filters.responseStatus = responsesInitialState.filters.responseStatus;
  store.filters.showTestPatients =
    responsesInitialState.filters.showTestPatients;
};

export const responsesFilterCleared = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesFiltersCleared | typeof ViewAllResponsesClicked>
>({
  sources: [ResponsesFiltersCleared, ViewAllResponsesClicked],
  sink({ store }) {
    clearFilters(store);
    store.filtersDirty = true;
  },
});

export const responsesFilterSearched = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesFilterSearched>
>({
  sources: [ResponsesFilterSearched],
  async sink({ store, select, broadcast, getStore, event }) {
    const token = getStore('user').accessToken;

    store.filtersDirty = false;
    /* eslint-disable @typescript-eslint/camelcase */

    if (event.payload.resetPage) {
      store.page = 1;
    }

    const payload: Payload<ResponsesSearchFiltersPayload> = {
      pagination: {
        with_count: true,
        page: store.page,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
      },
      filters: select<keyof VistaProjections>('responses.search-payload').value,
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/responses/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        ResponsesSearchFailed({
          payload,
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
    /* eslint-enable @typescript-eslint/camelcase */
  },
});

export const clearErrorState = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesSearchFailedDialogDismissed>
>({
  sources: [ResponsesSearchFailedDialogDismissed],
  sink({ store }) {
    store.error = null;
  },
});

export const clearResponsesState = createSink<
  ResponsesState,
  ReturnType<typeof UserAccessTokenInvalid | typeof OrganizationChanged>
>({
  sources: [UserAccessTokenInvalid, OrganizationChanged],
  async sink({ store }) {
    store.data = responsesInitialState.data;
    store.count = responsesInitialState.count;
    store.error = responsesInitialState.error;
    store.loading = responsesInitialState.loading;
    store.page = responsesInitialState.page;
    store.sortCol = responsesInitialState.sortCol;
    store.sortDir = responsesInitialState.sortDir;
    store.filtersDirty = responsesInitialState.filtersDirty;
    store.tableMessage = responsesInitialState.tableMessage;
    clearFilters(store);
  },
});

export const responsesTableSortedSink = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesTableSorted>
>({
  sources: [ResponsesTableSorted],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(ResponsesFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Sorting now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(ResponsesFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const responsesPageUpdated = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesPageUpdated>
>({
  sources: [ResponsesPageUpdated],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.page = event.payload.page;
      broadcast(ResponsesFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Changing the page now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.page = event.payload.page;
      broadcast(ResponsesFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const responsesExportClicked = createSink<
  ResponsesState,
  ReturnType<typeof ResponsesExportClicked>
>({
  sources: [ResponsesExportClicked],
  async sink({ store, broadcast, getStore, select }) {
    /* eslint-disable @typescript-eslint/camelcase */
    const token = getStore('user').accessToken;
    const csv_user_date = new Date().toISOString();

    const payload: Payload<ResponsesSearchFiltersPayload> = {
      pagination: {
        page: store.page,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
      },
      filters: {
        ...select<keyof VistaProjections>('responses.search-payload').value,
        csv_user_date,
      },
    };
    /* eslint-enable @typescript-eslint/camelcase */

    downloadFromApi(
      '/api/vista/responses/search.csv',
      payload,
      store,
      token,
      broadcast,
      ResponsesExportFailed,
    );
  },
});

export const viewAllResponsesSink = createSink<
  ResponsesState,
  ReturnType<typeof ViewAllResponsesClicked>
>({
  sources: [ViewAllResponsesClicked],
  async sink({ store, event, broadcast }) {
    store.filters.clinicPatientCode = event.payload.patientId;
    store.filters.question = event.payload.question;
    window.scrollTo(0, 0);

    broadcast(ResponsesFilterSearched({ origin: 'search', resetPage: true }));
  },
});
