import { Payload, EscalationsSortCol, SortDir } from '@/shared';
import { action, payload } from 'ts-action';
import {
  EscalationsFilters,
  EscalationsFiltersPayload,
  EscalationsTableRow,
} from '../models';

export const EscalationsFilterChanged = action(
  '[ component | Escalations Filters ] Filter Changed',
  payload<{ input: keyof EscalationsFilters; value: string }>(),
);

export const EscalationsFiltersCleared = action(
  '[ component | EscalationsFilter ] Clear All',
);

export const EscalationsFilterSearched = action(
  '[ component | EscalationsFilter ] Searched',
  payload<{ origin: 'search' | 'sort'; resetPage?: boolean }>(),
);

export const EscalationsSearchFailed = action(
  '[ api | vista/escalations/search ] Escalations Search Failed',
  payload<{
    payload: Payload<EscalationsFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const EscalationResponsesSearchFailed = action(
  '[ api | vista/escalations/responses ] Escalation Responses Search Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const EscalationsTableSorted = action(
  '[ component | Escalations Table ] Table Sorted',
  payload<{ col: EscalationsSortCol; dir: SortDir }>(),
);
export const EscalationsPageUpdated = action(
  '[ component | Escalations Table ] Page Updated',
  payload<{ page: number }>(),
);

export const EscalationResponsesRequested = action(
  '[component | Escalations Table] Responses Requested',
  payload<{ id: number }>(),
);

export const EscalationsRowExpanded = action(
  '[component | Escalations Table ]Row Expanded',
  payload<{ item: EscalationsTableRow; isExpanded: boolean }>(),
);
