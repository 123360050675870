import { activitiesModule, ActivitiesProjectionsMap } from '@/activity';
import { capabilitiesModule, CapabilitiesProjectionsMap } from '@/capabilities';
import { escalationsModule, EscalationsProjectionsMap } from '@/escalations';
import { feedbackModule, FeedbackProjectionsMap } from '@/feedback';
import {
  organizationsModule,
  OrganizationsProjectionsMap,
} from '@/organizations';
import { PatientsProjectionsMap } from '@/patients/+state/projections';
import { PatientsDetailSummaryCaregiverProjectionMap } from '@/patients/detail/summary/caregiver/+state/projections';
import { PatientsDetailSummarySessionsProjections } from '@/patients/detail/summary/sessions/+state/projections';
import { reportModule } from '@/reporting/+state';
import { ReportsProjectionsMap } from '@/reporting/+state/projections-map';
import { responsesModule, ResponsesProjectionsMap } from '@/responses';
import { userModule, UserProjectionsMap } from '@/user';
import { Sink } from '@conversa/sink';
import {
  patientEngagementModule,
  PatientEngagementProjectionsMap,
} from './patient-engagements';
import { patientsModule } from './patients/+state/module';
import { patientsDetailModule } from './patients/detail/+state/module';
import { PatientsDetailProjectionMap } from './patients/detail/+state/projections';
import { patientsDetailSummaryCaregiverModule } from './patients/detail/summary/caregiver/+state/module';
import { patientsDetailSummaryEnrollmentsModule } from './patients/detail/summary/enrollments/+state/module';
import { PatientsDetailSummaryEnrollmentsProjections } from './patients/detail/summary/enrollments/+state/projections';
import { patientsDetailSummarySessionsModule } from './patients/detail/summary/sessions/+state/module';
import { programsModule, ProgramsProjectionsMap } from './programs/+state';
import { providersModule, ProvidersProjectionsMap } from './providers/+state';
import { RouteProjectionsMap, routerModule } from './router/+state/index';
import { miscModule } from './+state/module';
import { MiscProjectionsMap } from './+state/projections';
import { patientDetailsActivitiesModule } from './patients/detail/activity/+state/module';
import { PatientDetailsActivitiesProjectionMap } from './patients/detail/activity/+state/projections';
import { PatientDetailsResponsesProjectionMap } from './patients/detail/responses/+state/projections';
import { patientDetailsResponsesModule } from './patients/detail/responses/+state/module';
import { patientDetailsCompletedChatsModule } from './patients/detail/completed-chats/+state/module';
import { PatientDetailsCompletedChatsProjectionMap } from './patients/detail/completed-chats/+state/projections';

export type VistaProjections = UserProjectionsMap &
  EscalationsProjectionsMap &
  ResponsesProjectionsMap &
  PatientEngagementProjectionsMap &
  PatientsProjectionsMap &
  OrganizationsProjectionsMap &
  CapabilitiesProjectionsMap &
  ProgramsProjectionsMap &
  ProvidersProjectionsMap &
  FeedbackProjectionsMap &
  ActivitiesProjectionsMap &
  RouteProjectionsMap &
  ReportsProjectionsMap &
  PatientsDetailSummarySessionsProjections &
  PatientsDetailSummaryEnrollmentsProjections &
  PatientsDetailProjectionMap &
  PatientDetailsActivitiesProjectionMap &
  PatientDetailsResponsesProjectionMap &
  PatientsDetailSummaryCaregiverProjectionMap &
  MiscProjectionsMap &
  PatientDetailsCompletedChatsProjectionMap;

export default new Sink<VistaProjections>({
  modules: [
    activitiesModule,
    capabilitiesModule,
    escalationsModule,
    feedbackModule,
    patientEngagementModule,
    organizationsModule,
    patientsDetailModule,
    patientDetailsActivitiesModule,
    patientDetailsResponsesModule,
    patientDetailsCompletedChatsModule,
    patientsDetailSummaryCaregiverModule,
    patientsDetailSummaryEnrollmentsModule,
    patientsDetailSummarySessionsModule,
    patientsModule,
    programsModule,
    providersModule,
    reportModule,
    responsesModule,
    routerModule,
    userModule,
    miscModule,
  ],
  logging: true,
});
