export const filterInputUpdate = ({ event, store }) => {
  const input = event.payload.input;

  if (Object.keys(store['filters']).includes(input)) {
    store['filters'][input] = event.payload.value;
    store.filtersDirty = true;
  } else {
    console.warn(
      'Event attempted to update a property on a store that does not exist',
      { event, store },
    );
  }
};
