










import sink from '@/sink';
import PatientStatusDialog from '@conversa/bedazzled/src/dialog/patients/PatientStatusDialog.vue';
import { defineComponent } from '@vue/composition-api';
import {
  PatientStatusChangeRequested,
  PatientStatusDialogDismissed,
} from './+state/events';

export default defineComponent({
  components: { PatientStatusDialog },
  setup() {
    return {
      optedOut: sink.select('patients.detail.header').value.optedOut,
      loading: sink.select('patients.loading'),
      errorMessage: sink.select('patients.detail.error-message'),
      cancel: sink.lazyBroadcast(PatientStatusDialogDismissed()),
      updateStatus: ({ optedOut }) =>
        sink.broadcast(
          PatientStatusChangeRequested({ status: optedOut ? 'in' : 'out' }),
        ),
    };
  },
});
