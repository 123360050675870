


















































import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
} from '@vue/composition-api';
import { EditUserDialogSave, userModule } from '@/user/+state';
import sink from '@/sink';

export default defineComponent({
  setup(props, { emit }) {
    const user = userModule.injectStore();
    const contactPhone = ref(null);
    const mobilePhone = ref(null);
    const alertThreshold = ref(null);
    const alertNotificationMethod = ref(null);

    const watchStopHandler = watchEffect(() => {
      const payload = {
        contactPhone: contactPhone.value,
        mobilePhone: mobilePhone.value,
        alertThreshold: alertThreshold.value,
        alertNotificationMethod: alertNotificationMethod.value,
      };

      emit(
        'formStatusDiff',
        (() => {
          for (const key in payload) {
            if (payload[key] !== user[key]) return true;
          }
          return false;
        })(),
      );
    });

    onBeforeUnmount(() => {
      watchStopHandler();
    });

    contactPhone.value = user.contactPhone;
    mobilePhone.value = user.mobilePhone;
    alertThreshold.value = user.alertThreshold;
    alertNotificationMethod.value = user.alertNotificationMethod;
    return {
      save: () => {
        const inputs = {
          contactPhone: contactPhone.value,
          mobilePhone: mobilePhone.value,
          alertThreshold: alertThreshold.value,
          alertNotificationMethod: alertNotificationMethod.value,
        };
        sink.broadcast(EditUserDialogSave(inputs));
      },
      user,
      contactPhone,
      mobilePhone,
      alertThreshold,
      alertNotificationMethod,
      normalizeNumber: value => value?.replace(/[^0-9]/g, ''),
      alertOptions: [
        { value: 'none', text: 'All Responses' },
        { value: 'green', text: 'All Color Responses' },
        { value: 'yellow', text: 'Yellow and Red' },
        { value: 'red', text: 'Red' },
      ],
      notificationOptions: [
        { value: 'email', text: 'Email' },
        { value: 'sms', text: 'Text' },
      ],
    };
  },
});
