export const formRules = {
  required: v =>
    (Array.isArray(v) ? !!v.length : !!v) || 'This field is required',
  phoneLength: v =>
    (v && v.replace(/\D/g, '').length === 10) ||
    'Phone Number must be 10 digits.',
  emailVal: v =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || 'Must be a valid email address.',
};
