import { formatISODate } from '@/shared';
import { createProjector } from '@conversa/sink';
import { chunk } from '@conversa/bedazzled/src/utils/chunk';
import { PatientSessionProjection } from '../models';
import { PatientsDetailSummarySessionsState } from '../models/state.interface';
import { TABLE_CONFIG } from '@conversa/bedazzled/src/constants/tableConfig';
export interface PatientsDetailSummarySessionsProjections {
  'patients.detail.summary.sessions.paginated': PatientSessionProjection[][];
  'patients.detail.summary.sessions.paginated.current-set': PatientSessionProjection[];
  'patients.detail.summary.sessions.count': number;
  'patients.detail.summary.sessions.page': number;
}

const patientsProjector = createProjector<
  PatientsDetailSummarySessionsProjections,
  PatientsDetailSummarySessionsState
>();

/**
 * The goal of this projection is to:
 *  - iterate as few times as possible
 *  - group items by page item limits via nested Arrays
 *  - group items in nested Arrays by scheduled_for
 *  - order the items in reverse from however they are provided via the API
 *
 * To get the page of items you need access to the current store page
 * and target the zero based index of pages here
 * @example
 * const page = store.page - 1;
 * const items = sink.select('patients.detail.summary.sessions.paginated').value[page]
 */
export const sessionsPaginated = patientsProjector<
  PatientSessionProjection[][]
>('patients.detail.summary.sessions.paginated', ({ store }) => {
  const sessions = store.data.map(session => {
    return {
      date: formatISODate(session.date),
      modules: session.survey_names.join(', '),
    };
  });

  return chunk(sessions, TABLE_CONFIG.scheduledChats.numRows);
});

export const sessionsPaginatedCurrentSet = patientsProjector<
  PatientSessionProjection[]
>(
  'patients.detail.summary.sessions.paginated.current-set',
  ({ store, select }) => {
    return select('patients.detail.summary.sessions.paginated').value[
      store.page - 1
    ];
  },
);

export const sessionCount = patientsProjector<number>(
  'patients.detail.summary.sessions.count',
  ({ select }) => {
    return select('patients.detail.summary.sessions.paginated').value.reduce(
      (acc, curr) => (acc += curr.length),
      0,
    );
  },
);

export const page = patientsProjector<number>(
  'patients.detail.summary.sessions.page',
  ({ store }) => store.page,
);
