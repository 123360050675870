



























import sink from '@/sink';
import { Dialog, DialogActions } from '@conversa/bedazzled/src/dialog';
import { InlineLoader, Alert } from '@/shared';
import { defineComponent } from '@vue/composition-api';
import { CaregiverStatusChangeRequested } from '../+state/events';
import { patientsDetailSummaryCaregiverModule as m } from '../+state/module';

export default defineComponent({
  components: {
    Alert,
    Dialog,
    DialogActions,
    InlineLoader,
  },
  setup(_, ctx) {
    const store = m.injectStore();
    const { active } = sink.select('patients.detail.summary.caregiver').value;

    return {
      active,
      dialogText: sink.select('patients.details.summary.status-dialog-text'),
      loading: store.loading,
      error: store.error,
      cancel: () => ctx.emit('dialog-closed'),
      updateStatus: sink.lazyBroadcast(
        CaregiverStatusChangeRequested({ active: !active }),
      ),
    };
  },
});
