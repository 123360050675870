import { format as formatDate, parseISO, isValid, parse } from 'date-fns';
import { content } from './content';
import { PatientLanguage, PatientStatus, ChatStatus } from './models';

export function isPositiveInteger(unknownNumber: unknown): boolean {
  if (!unknownNumber) {
    return false;
  }

  const potentialNumber = Number(unknownNumber);

  return (
    !isNaN(potentialNumber) &&
    Number.isInteger(potentialNumber) &&
    potentialNumber > 0
  );
}

export const isISODate = dateString => isValid(parseISO(dateString));

export const getISOFormat = dateString => new Date(dateString).toISOString();

export function formatISODate(
  isoDateString: string,
  format = 'MM/dd/yyyy',
  defaultValue = 'N/A',
) {
  let formatted: string;
  try {
    formatted = formatDate(parseISO(isoDateString), format);
  } catch (error) {
    console.warn('formatISODate failed - using default value as fallback', {
      isoDateString,
      format,
      error,
    });
    formatted = defaultValue;
  }

  return formatted;
}

export const isDateFormatValid = date =>
  new RegExp('^([0-9]{2}/[0-9]{2}/[0-9]{4})?$').test(date);

export const dateFormatRule = (date: string) =>
  isDateFormatValid(date) || 'Date must be in the format of MM/DD/YYYY';

export const isDateValid = (date: string) =>
  !date.length || isValid(parse(date, 'MM/dd/yyyy', new Date()));

export const dateValidRule = (date: string) =>
  isDateValid(date) || 'Date must be valid';

export const dateRangeValid = ({
  dateISOString,
  minDateISOString,
  maxDateISOString,
}) =>
  new Date(dateISOString) >= new Date(minDateISOString) &&
  new Date(dateISOString) <= new Date(maxDateISOString);

export const dateRangeValidRule = ({
  dateISOString,
  minDateISOString,
  maxDateISOString,
}) =>
  dateRangeValid({ dateISOString, minDateISOString, maxDateISOString }) ||
  `Date must be between ${formatISODate(minDateISOString)} and ${formatISODate(
    maxDateISOString,
  )} `;

export const getFullISODate = (isoDateString: string) => {
  try {
    return parseISO(isoDateString).toISOString();
  } catch (error) {
    console.warn('getFullISODate - could not get iso date with timestamp', {
      isoDateString,
      error,
    });

    return isoDateString;
  }
};

export function humanReadableLanguage(language: PatientLanguage) {
  switch (language) {
    case 'en':
      return content.en.language.en;

    case 'es':
      return content.en.language.es;

    default:
      return language;
  }
}

export function humanReadableActiveCaregiver(hasActive: 't' | 'f') {
  switch (hasActive) {
    case 't':
      return content.en.activeCaregiver.t;

    case 'f':
      return content.en.activeCaregiver.f;

    default:
      return hasActive;
  }
}

export function humanReadablePatientStatus(status: PatientStatus) {
  switch (status) {
    case 'opted_in':
      return content.en.patientStatus.in;

    case 'opted_out':
      return content.en.patientStatus.out;

    default:
      return status;
  }
}

export function humanReadableChatStatus(status: ChatStatus) {
  return content.en.chatStatus[status] || status;
}

export function buildFilterPayload<T>(
  /* eslint-disable */
  store: { filters: { [k: string]: any } },
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
  isoDateKeys?: string[],
) {
  return Object.keys(store.filters).reduce((filters, key) => {
    const val = store.filters[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const filterKey = paramMap.get(key);

      if (filterKey) {
        filters[filterKey] = isoDateKeys?.includes(key)
          ? new Date(val).toISOString()
          : val;
      } else {
        console.warn(`${context} attempted to set an unknown filter`, {
          filterKey,
        });
      }
    }

    return filters;
  }, {}) as T;
}

/* eslint-disable @typescript-eslint/camelcase */
export function mapPayload<T>(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  srcParams: Record<string, any>,
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
) {
  return Object.keys(srcParams).reduce((targetParams, key) => {
    const val = srcParams[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const paramKey = paramMap.get(key);

      if (paramKey) {
        targetParams[paramKey] = val;
      } else {
        console.warn(`${context} attempted to map an unknown parameter`, {
          paramKey,
        });
      }
    }

    return targetParams;
  }, {}) as T;
  /* eslint-enable @typescript-eslint/camelcase */
}

export function handleErrorMessage({ store }) {
  const errorMessage = [];

  const message = store.error?.message;

  const detailErrorsIsArray = Array.isArray(store.error?.details?.errors);
  const details =
    (detailErrorsIsArray && store.error.details.errors[0]?.message) ||
    store.error?.details;

  const errors =
    detailErrorsIsArray && store.error.details.errors[0]?.value?.toString();

  if (message) errorMessage.push(message);
  if (details) errorMessage.push(`- ${details}`);
  if (errors) errorMessage.push(`: ${errors}`);

  return errorMessage.join(' ');
}

export const setLastRoute = (path: string) => {
  sessionStorage.setItem('lastRoute', path);
};

export const getLastRoute = () => {
  return sessionStorage.getItem('lastRoute');
};

export const removeLastRoute = () => {
  sessionStorage.removeItem('lastRoute');
};

export const setOrgId = (id: string) => {
  sessionStorage.setItem('selectedOrgId', id);
};

export const getOrgId = () => {
  return sessionStorage.getItem('selectedOrgId');
};

export const removeOrgId = () => {
  sessionStorage.removeItem('selectedOrgId');
};
