




import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { ErrorDialog } from '@/shared/components';
import { ExportFailedDismissDialog } from '@/+state/events';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      error: sink.select('exports.error-message'),
      dismissed: sink.lazyBroadcast(ExportFailedDismissDialog()),
    };
  },
});
