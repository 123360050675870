<template>
  <div class="expandable-card">
    <div class="expandable-mask" ref="maskRef" :style="maskStyleObj">
      <div class="content" ref="contentRef" v-html="safeContent"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions = {
  allowedTags: ['br', 'hr', 'em', 'strong'],
};

export default defineComponent({
  name: 'ExpandableCard',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    previewLines: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  setup(props) {
    const contentRef = ref(null);
    const maskRef = ref(null);

    const makeSafeContent = function(unsafeContent) {
      const unsafeReplacedContent = unsafeContent.replace(/\n/g, '<br />\n');

      return sanitizeHtml(unsafeReplacedContent, sanitizeOptions);
    };

    const safeContent = ref(makeSafeContent(props.content));
    const isMaskEnabled = ref(false);

    const updateContentMask = function() {
      const contentHeight = contentRef.value?.clientHeight;
      const maskHeight = maskRef.value?.clientHeight;

      if (!contentHeight || !maskHeight) {
        return;
      }

      isMaskEnabled.value = contentHeight > maskHeight + 10;
    };

    watch(
      () => props.content,
      () => {
        safeContent.value = makeSafeContent(props.content);
      },
    );

    watch(
      () => safeContent.value,
      () => {
        setTimeout(() => {
          updateContentMask();
        }, 100);
      },
    );

    setTimeout(() => {
      updateContentMask();
    }, 500);

    const maskStyleObj = computed(() => {
      const contentHeight = contentRef.value?.clientHeight || 0;
      const contentLines = contentHeight / 16; // lines are 16px
      let linesToShow = props.previewLines;

      if (contentLines <= props.previewLines) {
        linesToShow = contentLines;
      }

      return {
        height: props.isOpen ? `${contentHeight}px` : `${linesToShow * 1.25}em`,
      };
    });

    return {
      isMaskEnabled,
      contentRef,
      maskRef,
      maskStyleObj,
      safeContent,
    };
  },
});
</script>

<style scoped lang="scss">
.expandable-mask {
  overflow: hidden;
  text-overflow: ellipsis;
  transition: height 250ms ease-out 0s;
}
</style>
