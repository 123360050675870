import {
  formatISODate,
  handleErrorMessage,
  humanReadableLanguage,
  noDataIndicator,
} from '@/shared';
import { humanReadableGender } from '@/shared/gender';
import { createProjector } from '@conversa/sink';

import * as models from '../models';

export interface PatientsDetailProjectionMap {
  'patients.detail.profile': models.PatientsDetailProfile;
  'patients.detail.header': models.HeaderData;
  'patients.detail.profile.loading': boolean;
  'patients.detail.error-message': string;
}

const projector = createProjector<
  PatientsDetailProjectionMap,
  models.PatientsDetailState
>();

export const patientsDetailStoreErrorMessage = projector<string>(
  'patients.detail.error-message',
  handleErrorMessage,
);

export const enrollmentsLoading = projector<boolean>(
  'patients.detail.profile.loading',
  ({ store }) => store.profileLoading,
);

export const patientsDetailProfile = projector<models.PatientsDetailProfile>(
  'patients.detail.profile',
  ({ store }) => {
    return {
      patientName: `${store.data?.first_name || noDataIndicator} ${store.data
        ?.last_name || noDataIndicator}`,
      providerName: `${store.data?.user?.first_name || noDataIndicator} ${store
        .data?.user?.last_name || noDataIndicator}`,
      email: store.data?.email || noDataIndicator,
      phone: store.data?.mobile_phone || noDataIndicator,
      birthdate: store.data?.date_of_birth
        ? formatISODate(
            store.data.date_of_birth.substring(0, 10),
            'MMM dd, yyyy',
          )
        : noDataIndicator,
      gender: humanReadableGender(store.data?.gender) || noDataIndicator,
      language:
        humanReadableLanguage(store.data?.preferred_language) ||
        noDataIndicator,
      patientStatus: store.data?.opted_out
        ? store.data?.opted_out_reason || 'Opted Out'
        : 'Opted in',
      termsAccepted: store.data?.accepted_terms_of_use_at
        ? formatISODate(store.data?.accepted_terms_of_use_at)
        : noDataIndicator,
      patientId: store.data?.id || noDataIndicator,
      preferredCommunication: store.data?.preferred_communication_channel,
      secondaryIds: store.data?.secondary_ids?.length
        ? store.data.secondary_ids.map(e => `${e.name}: ${e.value}`)
        : [noDataIndicator],
    };
  },
);

export const headerData = projector<models.HeaderData>(
  'patients.detail.header',
  ({ store }) => {
    return {
      patientName: `${store.data?.first_name || noDataIndicator} ${store.data
        ?.last_name || noDataIndicator}`,
      createdAt: store.data?.created_at
        ? formatISODate(store.data?.created_at)
        : noDataIndicator,
      patientId: store.data?.id ? store.data.id.toString() : noDataIndicator,
      clinicPatientCode: store.data?.clinic_patient_code || noDataIndicator,
      optedOut: store.data?.opted_out,
    };
  },
);
