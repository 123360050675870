






















import { defineComponent, ref } from '@vue/composition-api';
import AncestryOption from './AncestryOption.vue';

export default defineComponent({
  components: { AncestryOption },
  emits: ['selected'],
  props: ['options', 'optionMap', 'selectedOption'],
  setup(props, { emit }) {
    const open = ref(false);
    return {
      selected: id => {
        open.value = false;
        emit('selected', id);
      },
      open,
      handleOpen: bool => (open.value = bool),
    };
  },
});
