import vuetify from '@/plugins/vuetify';
import VueCompositionApi from '@vue/composition-api';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueCompositionApi);
Vue.use(VueTippy);
Vue.use(VueCookies);

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
