import { Payload, ResponsesSortCol, SortDir } from '@/shared';
import { action, payload } from 'ts-action';
import { ResponsesFilters, ResponsesSearchFiltersPayload } from '../models';

export const ResponsesFilterChanged = action(
  '[ component | Responses Filters ] Filter Changed',
  payload<{ input: keyof ResponsesFilters; value: string }>(),
);

export const ResponsesFiltersCleared = action(
  '[ component | ResponsesFilter ] Clear All',
);

export const ResponsesFilterSearched = action(
  '[ component | ResponsesFilter ] Searched',
  payload<{ origin: 'search' | 'sort'; resetPage?: boolean }>(),
);

export const ResponsesSearchFailed = action(
  '[ api | vista/responses/search ] Responses Search Failed',
  payload<{
    payload: Payload<ResponsesSearchFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const ResponsesSearchFailedDialogDismissed = action(
  '[ component | ResponsesSearchFailedDialog ] Dismissed',
);

export const ResponsesTableSorted = action(
  '[ component | Responses Table ] Table Sorted',
  payload<{ col: ResponsesSortCol; dir: SortDir }>(),
);
export const ResponsesPageUpdated = action(
  '[ component | Responses Table ] Page Updated',
  payload<{ page: number }>(),
);

export const ResponsesExportClicked = action(
  '[ component | Responses Page ] Export Clicked',
);

export const ResponsesExportFailed = action(
  '[ api | vista/responses/export ] Responses Export Failed',
  payload<{
    payload: Payload<ResponsesSearchFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const ViewAllResponsesClicked = action(
  '[ component | Escalations Page ] View All Responses Clicked',
  payload<{
    patientId: string;
    question: string;
  }>(),
);
