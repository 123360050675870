import { render, staticRenderFns } from "./ActionCard.vue?vue&type=template&id=23975c28&scoped=true&"
import script from "./ActionCard.vue?vue&type=script&lang=ts&"
export * from "./ActionCard.vue?vue&type=script&lang=ts&"
import style0 from "./ActionCard.vue?vue&type=style&index=0&id=23975c28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23975c28",
  null
  
)

export default component.exports