






























import { Alert } from '@conversa/bedazzled/src/alerts';
import { Dialog, DialogActions } from '@conversa/bedazzled/src/dialog';
import { InlineLoader } from '@conversa/bedazzled/src/loaders';
import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  optedOut: boolean;
  loading: boolean;
  hasError: boolean;
}>({
  props: ['optedOut', 'loading', 'hasError'],
  emits: ['cancel', 'update-status'],
  components: {
    Alert,
    Dialog,
    DialogActions,
    InlineLoader,
  },
  setup({ optedOut }, ctx) {
    return {
      dialogText: optedOut
        ? {
            title: 'Opt Patient In?',
            description:
              'Would you like to Opt the patient In? Opting the patient in will restart the chat schedule for enrolled programs. Recalcuation of the schedule will be processed overnight. If you would like to recalculate the schedule immediately you will need to unenroll then reenroll them from any programs.',
            button: 'Opt Patient In',
            loading: 'Opting Patient In...',
          }
        : {
            title: 'Opt Patient Out?',
            description:
              'Would you like to Opt the patient Out? Opting the patient Out will stop all chat notifications and will remove any scheduled chats for enrolled programs but will not unenroll them from any programs.',
            button: 'Opt Patient Out',
            loading: 'Opting Patient Out...',
          },
      cancel: () => ctx.emit('cancel'),
      updateStatus: () => ctx.emit('update-status', { optedOut }),
    };
  },
});
