import { tableMessages } from '@/shared';
import { createStoreV2 } from '@conversa/sink';
import { PatientDetailsResponsesState } from '../../models/patient-details-responses-state.interface';

export const patientDetailsResponsesStateKey = 'patients-detail-responses';

export const patientDetailsResponsesInitialState: PatientDetailsResponsesState = {
  data: [],
  count: null,
  loading: false,
  page: 1,
  sortCol: 'responseDate',
  sortDir: 'desc',
  tableMessage: tableMessages.preSearch,
};

export const patientDetailsResponsesStore = createStoreV2(
  patientDetailsResponsesStateKey,
  patientDetailsResponsesInitialState,
);
