import { ErrorState } from '@/shared/models/error-state.interface';
import { action, payload } from 'ts-action';
import { Enrollment } from '../models';

export const EnrollmentEditClicked = action(
  '[ component | Program Row ] Enrollment Edit Button Clicked',
  payload<{ id: number; enrollmentId: number }>(),
);

export const PatientEnrollmentInformationMounted = action(
  '[ component | Patient Enrollment Information ] Mounted',
);

export const EnrollmentSubmitted = action(
  '[ component | Enrollment Create Dialog ] Enrollment Submitted',
  payload<{
    authoringId: number;
    concepts: { id: number; value: string | string[] }[];
  }>(),
);

export const PatientEnrollmentSuccessful = action(
  '[ api | post patients/:id/enrollments ] Enrollment Created',
  payload<{ patientId: number; enrollment: Enrollment }>(),
);

export const PatientEnrollmentFailed = action(
  '[ api | post patients/:id/enrollments ] Enrollment Creation Failed',
  payload<{
    error: ErrorState;
  }>(),
);

export const EnrollmentUpdateSubmitted = action(
  '[ component | EnrollmentDetailsDialog ] Update Submitted',
  payload<{
    authoringId: number;
    concepts: { id: number; value: string | string[] }[];
  }>(),
);

export const EnrollmentUpdateSucceeded = action(
  '[ api | patch patients/:id/enrollments/:survey_authoring_id ] Enrollment Updated',
);

export const EnrollmentUpdateFailed = action(
  '[ api | patch patients/:id/enrollments/:survey_authoring_id ] Enrollment Update Failed',
);

export const EnrollmentCreateDialogBackClicked = action(
  '[ component | Enrollment Create Dialog ] Back Clicked',
);

/**
 * Used in @/user/+state/sinks.clearErrorState
 */
export const EnrollmentDetailsUnmounted = action(
  '[ component | Enrollments Shell ] Enrollment Detail Unmounted',
);

export const EnrollmentChangeStatusClicked = action(
  '[ component | Program Row ] Unenroll Reenroll Button Clicked',
  payload<{ id: number; enrollmentId: number }>(),
);

export const ReenrollRequested = action(
  '[ component | Enrollment Change Status Dialog ] Reenroll Requested',
  payload<{ authoringId: number }>(),
);

export const UnenrollRequested = action(
  '[ component | Enrollment Change Status Dialog ] Unenroll Requested',
  payload<{ authoringId: number }>(),
);

export const EnrollmentChangeStatusSucceeded = action(
  '[ api | patch patients/:id/enrollments/:survey_authoring_id/status ] Enrollment Change Status Succeded',
);

export const EnrollmentChangeStatusFailed = action(
  '[ api | patch patients/:id/enrollments/:survey_authoring_id/status ] Enrollment Change Status Failed',
);

export const EnrollPatientClicked = action(
  '[ component | Enroll Patient Dialog ] Enroll Patient Clicked',
);

export const EnrollmentsFetchFailed = action(
  '[ api | vista/patients/enrollments ] Enrollments Fetch Failed',
  payload<{
    error: ErrorState;
  }>(),
);

export const SurveySelected = action(
  '[ component | Enrollment Program List ] Program Selected',
  payload<{ id: number }>(),
);

export const SurveysFetchFailed = action(
  '[ api | vista/patients/:id/surveys] Surveys Fetch Failed',
  payload<{ error: ErrorState }>(),
);
