







import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { ErrorDialog } from '@/shared/components';
import { DeeplinkFetchFailedDismissDialog } from '@/+state/events';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      dismissed: sink.lazyBroadcast(DeeplinkFetchFailedDismissDialog()),
    };
  },
});
