import { Payload } from '@/shared';
import { ValidationError } from '@/shared/models/validation-errors.interface';
import { action, payload } from 'ts-action';
import { Patient, PatientCreatePayload, PatientsFilters } from '../models';
import { PatientSearchFiltersPayload } from '../models/patients-search-payload.interface';
import { PatientSortCol } from './../../shared/models/sort-col.type';
import { SortDir } from './../../shared/models/sort-dir.type';

export const PatientsFilterChanged = action(
  '[ component | Patients Filters ] Filter Changed',
  payload<{ input: keyof PatientsFilters; value: string }>(),
);

export const AddPatientDialogSave = action(
  '[ component | AddPatientDialog ] Save',
  payload<PatientCreatePayload>(),
);

export const AddPatientDialogSaveBeforeEnroll = action(
  '[ component | AddPatientDialog ] Save Before Enroll',
  payload<PatientCreatePayload>(),
);

export const NewPatientInvalid = action(
  '[ api | Patients ] New Patient Invalid',
  payload<{
    payload: PatientCreatePayload;
    status: number;
    errors: ValidationError[];
  }>(),
);

export const NewPatientFailed = action(
  '[ api | Patients ] New Patient Failed',
  payload<{
    payload: PatientCreatePayload;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const NewPatientSaved = action(
  '[ api | Patients ] New Patient Saved',
  payload<Patient>(),
);

export const NewPatientSavedBeforeEnroll = action(
  '[ api | Patients ] New Patient Saved Before Enroll',
  payload<Patient>(),
);

export const PatientsFiltersCleared = action(
  '[ component | PatientsFilter ] Clear All',
);

export const PatientsFilterSearched = action(
  '[ component | PatientsFilter ] Searched',
  payload<{ origin: 'search' | 'sort'; resetPage?: boolean }>(),
);

export const PatientsSearchFailed = action(
  '[ api | vista/patients/search ] Patients Search Failed',
  payload<{
    payload: Payload<PatientSearchFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

/**
 * Used in src/patients/+state/sinks.ts
 */
export const PatientSearchFailedDialogDismissed = action(
  '[ component | PatientSearchFailedDialog ] Dismissed',
);

export const PatientsTableSorted = action(
  '[ component | Patients Table ] Table Sorted',
  payload<{ col: PatientSortCol; dir: SortDir }>(),
);

export const PatientsPageUpdated = action(
  '[ component | Patients Table ] Page Updated',
  payload<{ page: number }>(),
);
