













import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';
import { ErrorState } from '../models/error-state.interface';

export default defineComponent<{ error: ErrorState }>({
  props: ['error'],
  components: { Dialog, DialogContent, DialogActions },
  setup(_, ctx) {
    return {
      close: () => ctx.parent.$emit('dialog-closed'),
    };
  },
});
