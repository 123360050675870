














import { Dialog, DialogActions } from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    Dialog,
    DialogActions,
  },
  setup(_, ctx) {
    return {
      acknowledge: () => ctx.emit('dialog-closed'),
    };
  },
});
