import { PatientEngagementsSortCol, SortDir, Payload } from '@/shared';
import {
  PatientEngagementsSearchFiltersPayload,
  PatientEngagementsFilters,
} from '../models';
import { action, payload } from 'ts-action';

export const PatientEngagementsFilterChanged = action(
  '[ component | PatientEngagement Filters ] Filter Changed',
  payload<{ input: keyof PatientEngagementsFilters; value: string }>(),
);

export const PatientEngagementsFiltersCleared = action(
  '[ component | PatientEngagementFilter ] Clear All',
);

export const PatientEngagementsFilterSearched = action(
  '[ component | PatientEngagementFilter ] Searched',
  payload<{ origin: 'search' | 'sort'; resetPage?: boolean }>(),
);

export const PatientEngagementsSearchFailed = action(
  '[ api | vista/patientEngagement/search ] PatientEngagement Search Failed',
  payload<{
    payload: Payload<PatientEngagementsSearchFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const PatientEngagementsSearchFailedDialogDismissed = action(
  '[ component | PatientEngagementsSearchFailedDialog ] Dismissed',
);

export const PatientEngagementsTableSorted = action(
  '[ component | PatientEngagement Table ] Table Sorted',
  payload<{ col: PatientEngagementsSortCol; dir: SortDir }>(),
);
export const PatientEngagementsPageUpdated = action(
  '[ component | PatientEngagement Table ] Page Updated',
  payload<{ page: number }>(),
);
