








































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import {
  isDateValid,
  dateFormatRule,
  dateValidRule,
  isDateFormatValid,
  formatISODate,
  getFullISODate,
  dateRangeValidRule,
} from '../utils';
import startOfToday from 'date-fns/startOfToday';

export default defineComponent({
  emits: ['change'],
  props: {
    isoDate: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    minISODate: {
      type: String,
      required: false,
    },
    maxISODate: {
      type: String,
      required: false,
    },
    additionalRules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const calendarOpen = ref(false);
    const textField = ref(null);
    const readableDate = ref(formatISODate(props.isoDate, 'MM/dd/yyyy', ''));

    watch(
      () => props.isoDate,
      newVal => {
        readableDate.value = formatISODate(newVal, 'MM/dd/yyyy', '');
      },
    );

    const requiredRule = dateString =>
      !!dateString.length || 'Date is required';

    const dateRangeRule = dateString => {
      const [month, day, year] = dateString.split('/');

      return dateRangeValidRule({
        dateISOString: `${year}-${month}-${day}`,
        minDateISOString: props.minISODate,
        maxDateISOString: props.maxISODate,
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rulesArray: any[] = [
      dateString => dateFormatRule(dateString),
      dateString => dateValidRule(dateString),
    ];

    const rules = computed(() => {
      if (props.required) {
        rulesArray.unshift(requiredRule);
      }

      if (props.minISODate && props.maxISODate) {
        rulesArray.push(dateRangeRule);
      }

      if (props.additionalRules.length) {
        rulesArray = rulesArray.concat(props.additionalRules);
      }

      return rulesArray;
    });

    const onCalendarClick = () => {
      textField.value.focus();
      calendarOpen.value = true;
    };

    const emit = val => {
      ctx.emit('change', val);
    };

    const onDateChange = newDate => {
      emit(getFullISODate(newDate));
    };

    const onTextChange = newDateString => {
      if (!newDateString.length) {
        return emit(newDateString);
      }

      if (isDateFormatValid(newDateString) && isDateValid(newDateString)) {
        const [month, day, year] = newDateString.split('/');

        return onDateChange(`${year}-${month}-${day}`);
      }

      return;
    };

    const onClearClick = () => emit('');

    const onTodayClick = () => onDateChange(startOfToday().toISOString());

    return {
      calendarOpen,
      textField,
      readableDate,
      rules,
      onCalendarClick,
      onDateChange,
      onTextChange,
      onClearClick,
      onTodayClick,
    };
  },
});
