




















































import sink from '@/sink';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@conversa/bedazzled/src/dialog';
import { Alert } from '@/shared';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { LogoutButtonClicked } from '../../+state/events';
import ProfileAccountInfo from './ProfileAccountInfo.vue';
import ProfileUserSettings from './ProfileUserSettings.vue';

export default defineComponent({
  components: {
    Dialog,
    DialogActions,
    DialogContent,
    ProfileAccountInfo,
    ProfileUserSettings,
    Alert,
  },
  setup(_, ctx) {
    const template = ref<'user-settings' | 'account-info'>('user-settings');
    const formStatusChanged = ref(false);

    return {
      template,
      formStatusChanged,
      currentTemplate: computed(
        () =>
          (template.value === 'user-settings' && ProfileUserSettings) ||
          ProfileAccountInfo,
      ),
      showSaveButton: computed(
        () => template.value === 'user-settings' && formStatusChanged.value,
      ),
      error: sink.select('user.error-message'),
      cancel: () => ctx.emit('dialog-closed'),
      logout: sink.lazyBroadcast(LogoutButtonClicked({ origin: 'profile' })),
    };
  },
});
