import { OrganizationChanged } from '@/organizations/+state/events';
import { filterInputUpdate, isAxiosError, Payload } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { downloadFromApi } from '@/shared/download-from-api';
import { tableMessages } from '@/shared/empty-table-messages';
import { sortColMap } from '@/shared/sort-col-map';
import { VistaProjections } from '@/sink';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { ActivitiesSearchFiltersPayload, ActivitiesState } from '../models';
import {
  ActivitiesExportClicked,
  ActivitiesExportFailed,
  ActivitiesFilterChanged,
  ActivitiesFilterSearched,
  ActivitiesPageUpdated,
  ActivitiesSearchFailed,
  ActivitiesSearchFailedDialogDismissed,
  ActivitiesTableSorted,
  ActivityFiltersCleared,
} from './events';
import { activitiesInitialState } from './store';

export const activitiesFilterChanged = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesFilterChanged>
>({
  sources: [ActivitiesFilterChanged],
  sink: filterInputUpdate,
});

const clearFilters = (store: ActivitiesState) => {
  store.filters.activityDateFrom =
    activitiesInitialState.filters.activityDateFrom;
  store.filters.activityDateTo = activitiesInitialState.filters.activityDateTo;
  store.filters.clinicPatientCode =
    activitiesInitialState.filters.clinicPatientCode;
  store.filters.firstName = activitiesInitialState.filters.firstName;
  store.filters.lastName = activitiesInitialState.filters.lastName;
  store.filters.patientStatus = activitiesInitialState.filters.patientStatus;
  store.filters.showTestPatients =
    activitiesInitialState.filters.showTestPatients;
  store.filters.providerIds = activitiesInitialState.filters.providerIds;
  store.filters.orgId = activitiesInitialState.filters.orgId;
  store.filters.activity = activitiesInitialState.filters.activity;
};

export const activityFilterClearAll = createSink<
  ActivitiesState,
  ReturnType<typeof ActivityFiltersCleared>
>({
  sources: [ActivityFiltersCleared],
  sink({ store }) {
    clearFilters(store);
    store.filtersDirty = true;
  },
});

export const activitiesFilterSearched = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesFilterSearched>
>({
  sources: [ActivitiesFilterSearched],
  async sink({ store, select, broadcast, getStore, event }) {
    const token = getStore('user').accessToken;

    store.filtersDirty = false;

    if (event.payload.resetPage) {
      store.page = 1;
    }

    const payload: Payload<ActivitiesSearchFiltersPayload> = {
      pagination: {
        /* eslint-disable @typescript-eslint/camelcase */
        with_count: true,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
        /* eslint-enable @typescript-eslint/camelcase */
        page: store.page,
      },
      filters: select<keyof VistaProjections>('activities.search-payload')
        .value,
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/activities/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        ActivitiesSearchFailed({
          payload,
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
  },
});

export const clearErrorState = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesSearchFailedDialogDismissed>
>({
  sources: [ActivitiesSearchFailedDialogDismissed],
  sink({ store }) {
    store.error = null;
  },
});

export const clearActivityState = createSink<
  ActivitiesState,
  ReturnType<typeof UserAccessTokenInvalid | typeof OrganizationChanged>
>({
  sources: [UserAccessTokenInvalid, OrganizationChanged],
  async sink({ store }) {
    store.data = activitiesInitialState.data;
    store.error = activitiesInitialState.error;
    store.count = activitiesInitialState.count;
    store.loading = activitiesInitialState.loading;
    store.page = activitiesInitialState.page;
    store.sortCol = activitiesInitialState.sortCol;
    store.sortDir = activitiesInitialState.sortDir;
    store.filtersDirty = activitiesInitialState.filtersDirty;
    store.tableMessage = activitiesInitialState.tableMessage;
    clearFilters(store);
  },
});

export const activitiesTableSortedSink = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesTableSorted>
>({
  sources: [ActivitiesTableSorted],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(ActivitiesFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Sorting now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(ActivitiesFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const activitiesPageUpdated = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesPageUpdated>
>({
  sources: [ActivitiesPageUpdated],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.page = event.payload.page;
      broadcast(ActivitiesFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Changing the page now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.page = event.payload.page;
      broadcast(ActivitiesFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const activitiesExportClicked = createSink<
  ActivitiesState,
  ReturnType<typeof ActivitiesExportClicked>
>({
  sources: [ActivitiesExportClicked],
  async sink({ store, broadcast, getStore, select }) {
    /* eslint-disable @typescript-eslint/camelcase */
    const token = getStore('user').accessToken;
    const csv_user_date = new Date().toISOString();

    const payload: Payload<ActivitiesSearchFiltersPayload> = {
      pagination: {
        page: store.page,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
      },
      filters: {
        ...select<keyof VistaProjections>('activities.search-payload').value,
        csv_user_date,
      },
    };
    /* eslint-enable @typescript-eslint/camelcase */

    downloadFromApi(
      '/api/vista/activities/search.csv',
      payload,
      store,
      token,
      broadcast,
      ActivitiesExportFailed,
    );
  },
});
